import { Injectable } from '@angular/core';
import { filter, first, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { APIType } from 'src/app/shared/models/api.model';
import { CouponReceiptContentModel, CouponReceiptPhoneVerificationContentModel } from 'src/app/shared/models/coupon-receipt.model';

@Injectable({
  providedIn: 'root',
})
export class CouponReceiptService {
  constructor(
    private readonly apiService: APIService,
    private readonly couponStore: CouponStore,
    private readonly languageService: LanguageService,
    private readonly applicationStore: ApplicationStore,
    private readonly accountQuery: AccountQuery,
    private readonly couponQuery: CouponQuery
  ) {}

  initialize(): void {
    this.apiService
      .get(APIType.StrapiCms, `sportsbook-bet-receipt-page?locale=en-ng&populate=*`)
      .pipe(
        tap((response: { data: { attributes: CouponReceiptContentModel } }) => {
          this.couponStore.updateCouponReceiptContent(response.data.attributes);
        })
      )
      .subscribe();

    if (this.accountQuery.isAuthenticated && !this.accountQuery.isPhoneVerified) {
      this.retrieveReceiptPhoneVerificationContent();
    } else {
      this.accountQuery.isAuthenticated$
        .pipe(
          filter(isAuthenticated => isAuthenticated),
          first()
        )
        .subscribe(() => {
          if (!this.accountQuery.isPhoneVerified) {
            this.retrieveReceiptPhoneVerificationContent();
          }
        });
    }
  }

  hideNavbar(): void {
    this.applicationStore.updateUI({
      showNavbar: false,
    });
  }

  showNavbar(): void {
    this.applicationStore.updateUI({
      showNavbar: true,
    });
  }

  loadingOn(): void {
    this.couponStore.setLoading(true);
  }

  loadingOff(): void {
    this.couponStore.setLoading(false);
  }

  private retrieveReceiptPhoneVerificationContent(): void {
    if (!this.couponQuery.hasCouponReceiptPhoneVerificationContent && !this.accountQuery.isPhoneVerified) {
      this.apiService
        .get(
          APIType.CMS,
          `CouponReceipt/ReceiptPhoneVerificationContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
        )
        .pipe(
          tap((response: CouponReceiptPhoneVerificationContentModel) => {
            this.couponStore.updateCouponReceiptPhoneVerificationContent(response);
          })
        )
        .subscribe();
    }
  }
}
