import { NgModule } from '@angular/core';
import { ExtraOptions, NoPreloading, RouterModule, Routes } from '@angular/router';
import { CountrySelectionComponent } from 'src/app/components/app-country-selection/app-country-selection.component';
import { SiteUnavailableComponent } from 'src/app/components/app-site-unavailable/app-site-unavailable.component';
import { AutoLoginResolver } from 'src/app/core/resolvers/auto-login-resolver';
import { MobileVerificationCampaignComponent } from 'src/app/modules/mobile-verification-campaign/mobile-verification-campaign.component';
import { RecentBetWrapperComponent } from 'src/app/modules/my-bets/components/recent-bet/recent-bet-wrapper/recent-bet-wrapper.component';
import { CouponCheckComponent } from 'src/app/shared/components/coupon-check/coupon-check.component';
import { LanguagePageComponent } from 'src/app/shared/components/langauge-page/language-page.component';
import { ProductType } from 'src/app/shared/models/product.model';
import { RebetResolver } from 'src/app/core/resolvers/rebet-resolver';
import { BookBetResolver } from 'src/app/core/resolvers/book-bet-resolver';
import { RedirectGuard } from 'src/app/core/guards/redirect-guard.service';
import { VirtualGuard } from './core/guards/virtual.guard';

const routes: Routes = [
  {
    path: '',
    resolve: { autoLogin: AutoLoginResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/landing/landing.module').then(module => module.LandingModule),
        pathMatch: 'full',
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/accounts/accounts.module').then(module => module.AccountsModule),
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'coupon',
        canActivate: [RedirectGuard],
        loadChildren: () => import('./modules/coupon/coupon.module').then(module => module.CouponModule),
        data: {
          productType: ProductType.SportsBook,
          inCoupon: true,
          inMyBets: false,
          isSportsSection: false,
          slowRollout: {
            percentageConfigKey: 'newBetslipTrafficPercent',
            variantPath: '/sports/betslip',
          },
          stayOnCurrentPath: true,
        },
      },
      {
        path: 'quick-coupon',
        loadChildren: () => import('./modules/quick-coupon/quick-coupon.module').then(module => module.QuickCouponModule),
        data: { productType: ProductType.SportsBook, inMyBets: false, inCoupon: false, isSportsSection: false },
      },
      {
        path: 'sports/mybets',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to mybets external site
        data: {
          externalUrl: `${window.location.origin}/my-bets/sports`,
        },
      },
      {
        path: 'sports/mybets/open',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to mybets external site
        data: {
          externalUrl: `${window.location.origin}/my-bets/sports/open`,
        },
      },
      {
        path: 'sports/mybets/settled',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to mybets external site
        data: {
          externalUrl: `${window.location.origin}/my-bets/sports/settled`,
        },
      },
      {
        path: 'my-bets/sports',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to external site
        data: {
          externalUrl: `${window.location.origin}/my-bets/sports`,
        },
      },
      {
        path: 'my-bets/virtuals',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to external site
        data: {
          externalUrl: `${window.location.origin}/my-bets/virtuals`,
        },
      },
      {
        path: 'my-bets/jackpotbets',
        loadChildren: () => import('./modules/my-bets/my-bets.module').then(module => module.MyBetsModule),
        data: { inMyBets: true, inCoupon: false },
      },
      {
        path: 'book-bet',
        loadChildren: () => import('./modules/book-bet/book-bet.module').then(module => module.BookBetModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'book-bet/:bookingCode',
        pathMatch: 'full',
        resolve: { bookBet: BookBetResolver },
        loadChildren: () => import('./modules/landing/landing.module').then(module => module.LandingModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, noCheck: true, isSportsSection: false },
      },
      {
        path: 'coupon-check',
        component: CouponCheckComponent,
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'coupon-check/:couponCode',
        component: RecentBetWrapperComponent,
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, noCheck: true, isSportsSection: false },
      },
      {
        path: 'last-minute',
        loadChildren: () => import('./modules/last-minute/last-minute.module').then(module => module.LastMinuteModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'live',
        canActivate: [RedirectGuard],
        loadChildren: () => import('./modules/live/live.module').then(module => module.LiveModule),
        data: {
          productType: ProductType.SportsBook,
          inCoupon: false,
          inMyBets: false,
          isLive: true,
          isSportsSection: false,
          slowRollout: {
            percentageConfigKey: 'newLivePagesTrafficPercent',
            variantPath: '/sports/live',
          },
          stayOnCurrentPath: true,
        },
      },
      {
        path: 'search',
        loadChildren: () => import('./modules/search/search.module').then(module => module.SearchModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'sports',
        loadChildren: () => import('./modules/sport/sport.module').then(module => module.SportModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: true },
      },
      {
        path: 'register',
        redirectTo: 'account/registration',
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'register-success',
        redirectTo: 'account/registration-success',
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'odds-less-than',
        loadChildren: () => import('./modules/odds-less-than/odds-less-than.module').then(module => module.OddsLessThanModule),
        pathMatch: 'full',
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'quick-bet',
        loadChildren: () => import('./modules/quick-bet/quick-bet.module').then(module => module.QuickBetModule),
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'message-center',
        loadChildren: () => import('./modules/message-center/message-center.module').then(module => module.MessageCenterModule),
        pathMatch: 'full',
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'help',
        loadChildren: () => import('./modules/help/help.module').then(module => module.HelpModule),
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'results/:product',
        loadChildren: () => import('./modules/results/results.module').then(module => module.ResultsModule),
        pathMatch: 'full',
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/virtuals/virtuals.module').then(module => module.VirtualsModule),
        data: { productType: ProductType.Virtuals },
        canActivate: [VirtualGuard],
      },
      {
        // redirect /virtuals and all its sub-paths to the standalone app
        path: 'virtuals',
        children: [
          {
            path: '**',
            canActivate: [RedirectGuard],
            children: [],
          },
        ],
      },
      {
        path: 'menu',
        loadChildren: () => import('./modules/menu/menu.module').then(module => module.MenuModule),
        pathMatch: 'full',
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'latest-jackpots',
        loadChildren: () => import('./modules/latest-won-jackpot/latest-won-jackpot.module').then(module => module.LatestWonJackpotModule),
        data: {
          productType: ProductType.Virtuals,
          virtualsLeagueType: undefined,
          inCoupon: false,
          inMyBets: false,
          isSportsSection: false,
        },
      },
      {
        path: 'latest-mkwanja-virtual-bonus',
        loadChildren: () => import('./modules/latest-won-jackpot/latest-won-jackpot.module').then(module => module.LatestWonJackpotModule),
        data: {
          productType: ProductType.Virtuals,
          virtualsLeagueType: undefined,
          inCoupon: false,
          inMyBets: false,
          isSportsSection: false,
        },
      },
      {
        path: 'download-app',
        loadChildren: () => import('./modules/app-download/app-download.module').then(module => module.AppDownloadModule),
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'language',
        component: LanguagePageComponent,
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'site-unavailable',
        component: SiteUnavailableComponent,
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'country-selection',
        component: CountrySelectionComponent,
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'mobile-verification',
        component: MobileVerificationCampaignComponent,
        data: { inCoupon: false, inMyBets: false, isSportsSection: false },
      },
      {
        path: 'jackpot-bets',
        canActivate: [RedirectGuard],
        component: RedirectGuard, // Any random thing here. This will redirect to JPB external site
        data: {
          externalUrl: `${window.location.origin}/jackpot-bets`,
        },
      },
      {
        // redirect /casino and all its sub-paths to the standalone app
        path: 'casino',
        children: [
          {
            path: '**',
            canActivate: [RedirectGuard],
            children: [],
          },
        ],
      },
      {
        // redirect /promotions and all its sub-paths to the standalone app
        path: 'promotions',
        children: [
          {
            path: '**',
            canActivate: [RedirectGuard],
            children: [],
          },
        ],
      },
      {
        // redirect /landing pages and all its sub-paths to the standalone app
        path: 'l',
        children: [
          {
            path: '**',
            canActivate: [RedirectGuard],
            children: [],
          },
        ],
      },
      {
        // redirect /blog and all its sub-paths to the standalone app
        path: 'blog',
        children: [
          {
            path: '**',
            canActivate: [RedirectGuard],
            children: [],
          },
        ],
      },
      {
        path: 'rebet/:couponCode',
        pathMatch: 'full',
        loadChildren: () => import('./modules/landing/landing.module').then(module => module.LandingModule),
        resolve: { rebet: RebetResolver },
        data: { productType: ProductType.SportsBook, inCoupon: false, inMyBets: false, noCheck: true, isSportsSection: false },
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
  preloadingStrategy: NoPreloading,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [RedirectGuard],
})
export class AppRoutingModule {}
