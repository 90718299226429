import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const grafanaFaroConfig = environment.appConfigDefaults.grafana.faro;
if (grafanaFaroConfig.enabled) {
  initializeFaro({
    url: grafanaFaroConfig.url,
    app: {
      name: grafanaFaroConfig.name,
      version: grafanaFaroConfig.version,
      environment: grafanaFaroConfig.environment,
    },
    sessionTracking: {
      samplingRate: grafanaFaroConfig.sessionTracking.samplingRate,
      persistent: grafanaFaroConfig.sessionTracking.persistent,
    },

    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation()
    ],
  });
}

document.addEventListener('DOMContentLoaded', () => {
  let storageSupported = false; try { storageSupported = (window.localStorage && true) } catch (e) { }
  if (!storageSupported) {
    Object.defineProperty(window, 'localStorage', {value: {
        _data       : {},
        setItem     : function(id, val) { return this._data[id] = String(val); },
        getItem     : function(id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined; },
        removeItem  : function(id) { return delete this._data[id]; },
        clear       : function() { return this._data = {}; }
      } });
    Object.defineProperty(window, 'sessionStorage', {value: {
        _data       : {},
        setItem     : function(id, val) { return this._data[id] = String(val); },
        getItem     : function(id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined; },
        removeItem  : function(id) { return delete this._data[id]; },
        clear       : function() { return this._data = {}; }
      } });
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => {
      console.error(err);
    });
});
