import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponReceiptService } from 'src/app/core/services/coupon/coupon-receipt.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { ButtonType } from 'src/app/shared/models/button.model';
import { circleLoaderSuccess } from 'src/assets/json/lottie-configs';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { BannerService } from 'src/app/core/services/banner.service';
import { DeviceInformationService } from 'src/app/core/services/device-information.service';
import { CouponGoalNotificationsService } from 'src/app/core/services/coupon/coupon-goal-notifications.service';
import { differenceInMilliseconds } from 'date-fns';

enum ReceiptState {
  Confirmation,
  PreVerification,
  InputNumber,
  InputOTP,
  NumberVerified,
  ContentUnavailable, // For if CMS content is not retrieved
}

@Component({
  selector: 'coupon-successful-bet',
  templateUrl: './coupon-successful-bet.component.html',
  styleUrls: ['./coupon-successful-bet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponSuccessfulBetComponent implements OnInit, OnDestroy {
  private readonly STAKE_KEY = '[[stake]]';
  private readonly hasFreeBetSelected$ = this.couponQuery.lastPlacedCoupon$.pipe(
    map(coupon => coupon?.BetDetails?.FreeBetDetails?.code !== undefined)
  );
  private numberChanged = false;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly NATIVE_USER_AGENTS = ['BTKApp', 'btkAppIos', 'btkAppAndroid'];
  private readonly SWITCH_LIMITER_IN_MS = 1000;
  private readonly lastSubscriptionOperation$ = new BehaviorSubject<Date>(undefined);
  private readonly isNative = this.NATIVE_USER_AGENTS.some(userAgent => this.deviceInformationService.getUserAgent().includes(userAgent));

  currencyFormatPipe: CurrencyFormatPipe;

  buttonType = ButtonType;
  inputOTPMessage: string[];
  inputOTPMessageHasUserPhoneNumber: boolean;
  otpCodeLength = this.appConfig.get('otp').otpCodeLength;
  receiptStates = ReceiptState;
  showTelExtDropdownArrow = true;
  winNotificationSetting: boolean = this.appConfig.get('account').enableWinNotificationSetting;
  betslipLoadCompleteOptions = circleLoaderSuccess;
  ctaAdditionalStyles = {
    fontSize: '14px',
    fontWeight: 'normal',
    minWidth: '110px',
    height: '40px',
    padding: '15px 24px',
    borderRadius: '32px',
  };
  messageCtaAdditionalStyles = {
    ...this.ctaAdditionalStyles,
    border: '1px solid #255DBD',
  };

  readonly invalidCode$ = new BehaviorSubject<boolean>(false);
  readonly limitExceeded$ = new BehaviorSubject<boolean>(false);
  readonly mobileNumber$ = new BehaviorSubject('');
  readonly mobilePrefix$ = new BehaviorSubject('');
  readonly otpInputGenericError$ = new BehaviorSubject<boolean>(false);
  readonly receiptContent$ = this.couponQuery.couponReceiptContent$;
  readonly receiptState$ = new BehaviorSubject<ReceiptState>(ReceiptState.ContentUnavailable);
  readonly verifyingCode$ = new BehaviorSubject<boolean>(false);
  readonly betSuccessTitle$ = this.hasFreeBetSelected$.pipe(map(hasFreeBet => this.handleTitleStake(hasFreeBet)));
  readonly flexiCut$ = this.couponQuery.lastPlacedCouponFlexicut$.pipe(map(flexicut => flexicut && `CUT ${flexicut}`));
  readonly accaBonusPercentage$ = this.couponQuery.lastPlacedCouponAccaBonus$.pipe(map(bonus => Boolean(bonus) && `${bonus}%`));
  readonly potentialWin$ = this.couponQuery.lastPlacedCoupon$.pipe(
    map(coupon => this.currencyFormatPipe.transform(coupon ? coupon.MaxWinNet : 0, '1.0-0'))
  );
  readonly bookingCode$ = this.couponQuery.lastPlacedCouponBookingCode$;
  readonly canRetainSelections$ = this.couponQuery.lastPlacedCouponCode$.pipe(map(code => !!code));
  readonly notificationsEnabled$ = new BehaviorSubject<boolean>(false);

  readonly isInEvaluation = this.couponQuery.isLastPlacedCouponInEvaluation;
  readonly showGoalNotificationsSwitch = this.isNative && this.couponQuery.showGoalNotificationsSwitchForNativeApps;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly couponQuery: CouponQuery,
    readonly registrationQuery: RegistrationQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly bannerService: BannerService,
    private readonly clipboard: Clipboard,
    private readonly couponGoalNotificationsService: CouponGoalNotificationsService,
    private readonly couponReceiptService: CouponReceiptService,
    private readonly couponService: CouponService,
    private readonly currencyService: CurrencyService,
    private readonly dataLayerService: DataLayerService,
    private readonly deviceInformationService: DeviceInformationService,
    private readonly languageService: LanguageService,
    private readonly ngNavigatorShareService: NgNavigatorShareService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {
    // This is placed here as putting in the OnInit lifecycle method will cause a visual glitch
    // where the navbar hiding is delayed
    this.couponReceiptService.hideNavbar();
    this.currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);
  }

  ngOnInit(): void {
    this.couponService.clearCouponData();

    this.receiptContent$.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        const bannerStateHistory = this.bannerService.getAllBannerHistoryState();
        for (const key in bannerStateHistory) {
          bannerStateHistory[key].isLastUser = false;
        }
        this.bannerService.setBannerState({
          ...bannerStateHistory,
          [String(this.accountQuery.userData.id)]: {
            bannerState: 'PlacedBet',
            isLastUser: true,
          },
        });
        // success
        this.receiptState$.next(
          this.accountQuery.isPhoneVerified || !this.winNotificationSetting
            ? this.couponQuery.hasCouponReceiptContent
              ? ReceiptState.Confirmation
              : ReceiptState.ContentUnavailable
            : this.couponQuery.hasCouponReceiptContent && this.couponQuery.hasCouponReceiptPhoneVerificationContent
            ? ReceiptState.PreVerification
            : ReceiptState.ContentUnavailable
        );
      },
      () => {
        // error
        this.receiptState$.next(ReceiptState.ContentUnavailable);
      }
    );
  }

  ngOnDestroy(): void {
    this.couponService.clearLastPlacedCoupon();
    this.couponReceiptService.showNavbar();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  navigateBack(): void {
    const updatePath = this.couponQuery.previousPagePath.includes('my-accounts/login') ? '/' : this.couponQuery.previousPagePath;
    window.location.href = updatePath;
  }

  proceedFromVerifyNumber(event: { prefix: string; number: string; numberChanged: boolean }): void {
    this.mobilePrefix$.next(event.prefix);
    this.mobileNumber$.next(event.number);
    this.numberChanged = event.numberChanged;

    this.navigateForward();
  }

  postOTP(): void {
    this.updateWinNotification();
    if (this.numberChanged) {
      // Refresh user data
      this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
    } else {
      // Just set phone and account statuses
      this.accountService.updatePhoneVerificationStatus('VER');
      this.accountService.updateUserStatus('ACT');
    }
  }

  navigateForward(): void {
    switch (this.receiptState$.value) {
      case ReceiptState.PreVerification:
        this.receiptState$.next(ReceiptState.InputNumber);
        break;
      case ReceiptState.InputNumber:
        this.receiptState$.next(ReceiptState.InputOTP);
        break;
      case ReceiptState.Confirmation:
      case ReceiptState.NumberVerified:
      default:
        this.router.navigate([this.couponQuery.couponReceiptConfirmationForwardCTAURL]);
        break;
    }
  }

  upsellNavigateForward(): void {
    this.router.navigate([this.couponQuery.couponReceiptUpsellForwardCTAURL]);
  }

  retainSelections(): void {
    if (this.couponQuery.lastPlacedCouponCode) {
      this.dataLayerService.createDataLayerEvent({
        event: 'Re-use Selections',
        couponCode: this.couponQuery.lastPlacedCouponCode,
      });
      this.couponService.rebetCoupon(this.couponQuery.lastPlacedCouponCode).pipe(first()).subscribe();
    }
  }

  performShareBet(): void {
    if (!this.couponQuery.lastPlacedCouponCode) {
      return;
    }

    if (!this.ngNavigatorShareService.canShare()) {
      const message = $localize`This service is not supported in your browser!`;
      this.notificationService.showErrorMessage(message);
      return;
    }

    const couponCode = this.couponQuery.lastPlacedCouponCode;
    const selection = this.couponQuery.lastPlacedCoupon.Odds.length;
    const stake = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.StakeGross);
    const potentialWin = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.MaxWinNet);

    this.dataLayerService.createDataLayerEvent({
      event: 'Share-bet-initiated',
      couponCode,
    });

    this.ngNavigatorShareService
      .share({
        title: $localize`Check out my BetKing bet! `,
        text: $localize`Check out my ${selection} selection bet! \nStake: ${stake} \nPotential Win: ${potentialWin}\n`,
        url: `/rebet/${couponCode}`,
      })
      .then(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'Share-bet-success',
          couponCode: this.couponQuery.lastPlacedCouponCode,
        });
      });
  }

  copyBookingCode(): void {
    this.clipboard.copy(this.couponQuery.lastPlacedCouponBookingCode);
    this.notificationService.showInfoMessage($localize`Booking code copied to clip board`, 2000, false);
  }

  toggleGoalNotifications(): void {
    // Request throttling so that user is not able to spam the API
    if (
      !this.lastSubscriptionOperation$.value ||
      differenceInMilliseconds(new Date(), this.lastSubscriptionOperation$.value) > this.SWITCH_LIMITER_IN_MS
    ) {
      let apiCall = undefined;
      if (this.notificationsEnabled$.value) {
        apiCall = this.couponGoalNotificationsService.disableGoalNotifications();
      } else {
        apiCall = this.couponGoalNotificationsService.enableGoalNotifications();
      }

      apiCall.subscribe(() => {
        this.notificationsEnabled$.next(!this.notificationsEnabled$.value);
      });

      this.lastSubscriptionOperation$.next(new Date(Date.now()));
    }
  }

  private updateWinNotification(): void {
    this.accountService.setWinNotification(true).subscribe(
      () => {
        // Success
        this.receiptState$.next(ReceiptState.NumberVerified);
      },
      () => {
        // Error
        this.otpInputGenericError$.next(true);
      }
    );
  }

  private handleTitleStake(isFreebet: boolean): string {
    const stake = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.StakeGross, '1.0-0');
    return (
      isFreebet ? this.couponQuery.couponReceiptConfirmationFreeBetHeader : this.couponQuery.couponReceiptConfirmationNormalBetHeader
    ).replace(this.STAKE_KEY, stake);
  }
}
